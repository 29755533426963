import React, { FC } from 'react';
import { renderDisclosures } from '@throttleup/esi-components';
import HTMLContentESIWrapper from '../../wrappers/HTMLContentESIWrapper';

interface IDisclosuresESI {
  parentId: number;
  cardMentions: number[] | undefined | null;
  nonAffiliate: number[] | undefined | null;
}

const DisclosuresESI: FC<IDisclosuresESI> = ({ parentId, cardMentions, nonAffiliate }) => {
  const cardMentionsClean = cardMentions?.join(',') || '';
  const nonAffiliateClean = nonAffiliate?.join(',') || '';

  // No need to create a disclosures section.
  if (cardMentionsClean === '' && nonAffiliateClean === '') {
    return null;
  }

  const rootPath = '/esi/components/?component=disclosures';
  const src = `${rootPath}&parent_id=${parentId}&card_mentions=${cardMentionsClean}&non_affiliate=${nonAffiliateClean}`;
  const containerId = `disclosures_${parentId}`;
  const extraParams = [cardMentionsClean, nonAffiliateClean, process.env['GATSBY_SITE_URL']];

  return (
    <HTMLContentESIWrapper
      cardId={0}
      containerId={containerId}
      src={src}
      fallbackRenderFunction={renderDisclosures}
      extraParams={extraParams}
      goLinkPlacing={'/TE/'}
    />
  );
};

export default DisclosuresESI;
