import React, { FC } from 'react';
import { IUserTooltip } from './UserTooltip.def';
import { Link } from 'gatsby';
import parse from 'html-react-parser';

const UserTooltip: FC<IUserTooltip> = ({ userDetails, closeClick }) => {
  const handleCloseClick = () => {
    closeClick();
  };

  const limitExcerptText = (text: string) => {
    if (text.length <= 200) {
      return text;
    } else {
      return text.slice(0, 200) + '...';
    }
  };

  function createMarkup(content: string) {
    return { __html: content };
  }

  const articlesPublished = userDetails.posts_authored !== 0 
  ? `${userDetails.posts_authored} Published Article${
    userDetails.posts_authored > 1 ? 's' : ''
  }` : false;

  const articlesEdited = userDetails.posts_edited !== 0 
  ? `${userDetails.posts_edited} Edited Article${
    userDetails.posts_edited > 1 ? 's' : ''
  }` : false;

  const countriesVisited = userDetails.countries_visited !== 0 
  ? `Countries Visited: ${userDetails.countries_visited}` 
  : false;

const usStatesVisited = userDetails.us_states_visited !== 0 
  ? `U.S. States Visited: ${userDetails.us_states_visited}` 
  : false;

  return (
    <div className="inline-tooltip author-editor-tooltip">
      <div className="tooltip-header">
        <button className="closeTooltip" onClick={handleCloseClick}>
          ×
        </button>
        <div className="editor-avatar">
          <Link className="learn-more-btn" to={userDetails.url}>
            <img src={userDetails.image} alt={userDetails.image_alt} loading="lazy" />
          </Link>
        </div>
        <div className="editor-data">
          <p className="editor-name">{userDetails.name}</p>
          <p
            className="editor-title"
            dangerouslySetInnerHTML={createMarkup(userDetails.designation)}
          />
         <p className="articles-published">
          {articlesPublished && <span>{articlesPublished}</span>}
          {articlesEdited && articlesPublished && <span className="text-spacer"> </span>} 
          {articlesEdited && <span>{articlesEdited}</span>} 
          </p>
          <p className="editor-travel-score">
          {countriesVisited && <span>{countriesVisited}</span>}
          {usStatesVisited && <span>{usStatesVisited}</span>}
          </p>
        </div>
      </div>
      <div className="tooltip-body">
        <div className="editor-excerpt">{parse(limitExcerptText(userDetails.quick_bio))}</div>
        <div className="editor-link">
          <Link className="learn-more-btn" to={userDetails.url}>
            Learn more
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserTooltip;
