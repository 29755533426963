import React, { DetailedHTMLProps, ImgHTMLAttributes, useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';
import { loadInfogramScript } from '../../components/Infogram';
import { processArticleContent } from '../../utils/processHTMLContent';
import FullWidthTemplateHeader from '../../components/Article/components/Templates/FullWidthTemplate/components/FullWidthTemplateHeader/FullWidthTemplateHeader';
import Seo from '../../components/Seo';
import ContentDislosure from '../../components/ContentDisclosure/ContentDisclosure';
import { createSourceElements, createSrc, createSrcSet } from '../../utils';
import {
  HT_DEFAULT_PAGE_NAME,
  LARGE_TAB_MIN_WIDTH_MQ,
  MEDIUM_TAB_MIN_WIDTH_MQ,
  MOBILE_MAX_WIDTH_MQ,
  SMALL_TAB_MIN_WIDTH_MQ,
} from '../../constants';
import TopSlider from '../../components/TopSlider';
import DisclosuresESI from '../../components/DisclosuresESI/DisclosuresESI';
import { createTemplateContext } from '../../utils/helpers';
import { isAdvertiserDisclosureDisabled } from '../../utils/helpers/toggleElements';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface IDataTemplate {
  page: Queries.WpPage;
}

const PaidLandingPageTemplate = ({ data, location }: PageProps<IDataTemplate>) => {
  const {
    page: { content = '', title, featuredImage, cardMentionsAll, nonAffiliateAll, databaseId },
  } = data;
  const context = createTemplateContext(data);
  const isDisabledAdvertiserDisclosure = isAdvertiserDisclosureDisabled(data);
  const { articleContent } = processArticleContent(content || '', '/TE/', 'Paid Landing Page');

  useEffect(() => {
    if (document.querySelectorAll('.article .infogram-embed').length > 0) {
      loadInfogramScript();
    }
  }, []);

  const imageSrc = featuredImage?.node?.sourceUrl;
  const image: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> | null =
    featuredImage?.node?.sourceUrl
      ? {
          src: createSrc(featuredImage.node.sourceUrl),
          alt: title || '',
        }
      : null;

  const sourceElements = createSourceElements([
    {
      media: LARGE_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(imageSrc || '', [
        {
          options: {
            width: 900,
          },
        },
      ]),
    },
    {
      media: MEDIUM_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(imageSrc || '', [
        {
          options: {
            width: 740,
          },
        },
      ]),
    },
    {
      media: SMALL_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(imageSrc || '', [
        {
          options: {
            width: 620,
          },
        },
      ]),
    },
    {
      media: MOBILE_MAX_WIDTH_MQ,
      srcSet: createSrcSet(imageSrc || '', [
        {
          options: {
            width: 700,
          },
        },
      ]),
    },
  ]);

  return (
    <HTPageWrapper
      category="Landing Page"
      title={title || HT_DEFAULT_PAGE_NAME}
      location={location}
    >
      <article className="template_salesLander">
        <TopSlider context={context} />
        <div className="credit-card-template-header">
          <div className="container">
            <FullWidthTemplateHeader title={title || ''} />
          </div>
        </div>
        <div className="container">
          {image && (
            <figure className="template-header__featured-image">
              <picture>
                {sourceElements}
                <img {...image} />
              </picture>
            </figure>
          )}
          {!isDisabledAdvertiserDisclosure && <ContentDislosure />}
          <div className="article">
            {articleContent}
            <DisclosuresESI
              parentId={databaseId}
              cardMentions={cardMentionsAll}
              nonAffiliate={nonAffiliateAll}
            />
          </div>
        </div>
      </article>
    </HTPageWrapper>
  );
};

export default PaidLandingPageTemplate;

export const Head = ({ data }: PageProps<IDataTemplate>) => {
  const { title, seo } = data.page;
  return (
    <>
      <Seo title={title || ''} wpSeo={seo} />
    </>
  );
};

export const pageQuery = graphql`
  query PaidLandingPageTemplate($id: String) {
    page: wpPage(id: { eq: $id }) {
      ...PageData
    }
  }
`;
