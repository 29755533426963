import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { IFullWidthTemplateHeader } from './FullWidthTemplateHeader.def';
import { createSourceElements, createSrc, createSrcSet } from '../../../../../../../utils';
import UserTooltip from '../../../../../../UserTooltip';
import parser from 'html-react-parser';
import { processCopyright } from '../../../../../../../utils/helpers';
import InlineTooltipWrapper from '../../../../../../../wrappers/InlineTooltipWrapper';
import {
  LARGE_TAB_MIN_WIDTH_MQ,
  MEDIUM_TAB_MIN_WIDTH_MQ,
  MED_DESKTOP_MIN_WIDTH_MQ,
  MOBILE_MAX_WIDTH_MQ,
  SMALL_DESKTOP_MIN_WIDTH_MQ,
  SMALL_TAB_MIN_WIDTH_MQ,
} from '../../../../../../../constants';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const FullWidthTemplateHeader: FC<IFullWidthTemplateHeader> = ({
  author,
  dateModified,
  datePublished = null,
  featuredImage,
  title,
  breadCrumbs,
  tooltip,
}) => {
  const [activeTooltip, setActiveTooltip] = useState('');
  const sourceElements = createSourceElements([
    {
      media: MED_DESKTOP_MIN_WIDTH_MQ,
      srcSet: createSrcSet(featuredImage?.src || '', [
        {
          options: {
            width: 1420,
            height: 650,
          },
        },
      ]),
    },
    {
      media: SMALL_DESKTOP_MIN_WIDTH_MQ,
      srcSet: createSrcSet(featuredImage?.src || '', [
        {
          options: {
            width: 1260,
            height: 650,
          },
        },
      ]),
    },
    {
      media: LARGE_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(featuredImage?.src || '', [
        {
          options: {
            width: 1000,
            height: 650,
          },
        },
      ]),
    },
    {
      media: MEDIUM_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(featuredImage?.src || '', [
        {
          options: {
            width: 740,
            height: 463,
          },
        },
      ]),
    },
    {
      media: SMALL_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(featuredImage?.src || '', [
        {
          options: {
            width: 620,
            height: 413,
          },
        },
      ]),
    },
    {
      media: MOBILE_MAX_WIDTH_MQ,
      srcSet: createSrcSet(featuredImage?.src || '', [
        {
          options: {
            width: 700,
          },
        },
      ]),
    },
  ]);

  if (featuredImage) {
    featuredImage = {
      ...featuredImage,
      src: createSrc(featuredImage?.src || ''),
    };
  }

  const postTitle = title || '';

  /**
   * This adjustment is required for those cases where the modified date is lower than the publish
   * date. This happens when a post has been published from a schedule.
   */
  let updatedDate = dateModified;
  if (datePublished && dateModified) {
    updatedDate = new Date(dateModified) > new Date(datePublished) ? dateModified : datePublished;
  }

  return (
    <header className="template-header">
      <div className="template-header__content-container">
        <ul className="template-header__breadcrumbs">
          {breadCrumbs?.length
            ? breadCrumbs.map((breadCrumb, index) => {
                return (
                  <li key={breadCrumb.text}>
                    {breadCrumb.link && <Link to={breadCrumb.link}>{breadCrumb.text}</Link>}
                    {breadCrumbs.length - 1 > index && (
                      <span className="template-header__breadcrumbs-divider">›</span>
                    )}
                  </li>
                );
              })
            : null}
        </ul>
        <h1 className="template-header__title">{parser(processCopyright(postTitle))}</h1>
        <div className="template-header__author-block">
          {author?.imageUrl && (
            <figure className="template-header__author-image">
              <img src={author.imageUrl} alt={author?.imageAlt || ''} height={50} width={50} />
            </figure>
          )}
          <div className="template-header__author-text-content">
            {author?.url && author?.name && (
              <InlineTooltipWrapper
                className="by-author"
                linkClassName="template-header__author-link"
                linkTo={author.url}
                linkChildren={author.name}
                TooltipComponentName={tooltip?.postAuthor ? UserTooltip : null}
                tooltipComponentProps={{ userDetails: tooltip?.postAuthor }}
                setActiveTooltip={setActiveTooltip}
                activeTooltip={activeTooltip}
              />
            )}
            {dateModified && (
              <span className="template-header__article-date">
                Updated:&nbsp;
                <meta
                  // @ts-ignore
                  itemprop="datePublished"
                  content={dayjs(datePublished ? datePublished : dateModified)
                    .tz('America/Chicago')
                    .format('YYYY-MM-DDTHH:mm:ssZ')}
                />
                <time
                  // @ts-ignore
                  itemprop="dateModified"
                  className="date updated"
                  datetime={dayjs(updatedDate).tz('America/Chicago').format('YYYY-MM-DDTHH:mm:ssZ')}
                >
                  {dayjs.utc(updatedDate).tz('America/Chicago').format('MMMM D, YYYY, h:mma z')}
                </time>
              </span>
            )}

            <div className="template-header__post-editors">
              {tooltip?.editorOne && (
                <div className="edited-by primary-editor">
                  <span className="edited-by-label">Edited by: </span>
                  <InlineTooltipWrapper
                    className="edited-by-name"
                    linkClassName="primary-editor-link"
                    linkTo={tooltip.editorOne.url}
                    linkChildren={tooltip.editorOne.name}
                    TooltipComponentName={UserTooltip}
                    tooltipComponentProps={{ userDetails: tooltip.editorOne }}
                    setActiveTooltip={setActiveTooltip}
                    activeTooltip={activeTooltip}
                  />
                </div>
              )}
              {tooltip?.editorTwo && (
                <div className="edited-by secondary-editor">
                  <span className="edited-by-label"> & </span>
                  <InlineTooltipWrapper
                    className="edited-by-name"
                    linkClassName="secondary-editor-link"
                    linkTo={tooltip.editorTwo.url}
                    linkChildren={tooltip.editorTwo.name}
                    TooltipComponentName={UserTooltip}
                    tooltipComponentProps={{ userDetails: tooltip.editorTwo }}
                    setActiveTooltip={setActiveTooltip}
                    activeTooltip={activeTooltip}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {featuredImage && (
        <figure className="template-header__featured-image">
          <picture>
            {sourceElements}
            <img {...featuredImage} />
          </picture>
        </figure>
      )}
    </header>
  );
};

export default FullWidthTemplateHeader;
