import React from 'react';
import { Link } from 'gatsby';

const ContentDislosure = () => (
  <div className="contentAdvertiserDisclosure">
    <p>
      We may be compensated when you click on product links, such as credit cards, from one or more
      of our advertising partners. Terms apply to the offers below. See our&nbsp;
      <Link to="/advertising-policy/">Advertising Policy</Link> for more about our partners, how we
      make money, and our rating methodology. Opinions and recommendations are ours alone.
    </p>
  </div>
);

export default ContentDislosure;
